/**
 * @file
 * Styles for Contextual Links
 */
 
@import "variables";

/**
 * Contextual links regions.
 */
.contextual-links-region {
  outline: none !important;
  //position: relative !important;
}
.contextual-links-region-active {
  outline: #999 dashed 1px !important;
}

/**
 * Contextual links.
 */
div.contextual-links-wrapper {
  display: none !important;
  font-size: 90% !important;
  position: absolute !important;
  right: 5px !important; /* LTR */
  top: 2px !important;
  z-index: 999 !important;
}
html.js div.contextual-links-wrapper {
  display: block !important;
}
a.contextual-links-trigger {
  background: transparent url(/modules/contextual/images/gear-select.png) no-repeat 2px 0 !important;
  border: 1px solid transparent !important;
  display: none !important;
  height: 18px !important;
  margin: 0 !important;
  padding: 0 2px !important;
  outline: none !important;
  text-indent: 34px !important; /* LTR */
  width: 28px !important;
  overflow: hidden !important;
  -khtml-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  border-radius: 4px !important;
}
a.contextual-links-trigger:hover,
div.contextual-links-active a.contextual-links-trigger {
  background-position: 2px -18px !important;
}
div.contextual-links-active a.contextual-links-trigger {
  background-color: #fff !important;
  border-color: #ccc !important;
  border-bottom: none !important;
  position: relative !important;
  z-index: 1 !important;
  -moz-border-radius: 4px 4px 0 0 !important;
  -webkit-border-bottom-left-radius: 0 !important;
  -webkit-border-bottom-right-radius: 0 !important;
  border-radius: 4px 4px 0 0 !important;
}
div.contextual-links-wrapper ul.contextual-links {
  font-size: 12px !important;
  font-family: sans-serif !important;
  background-color: #fff !important;
  border: 1px solid #ccc !important;
  display: none !important;
  margin: 0 !important;
  padding: 0.25em 0 !important;
  position: absolute !important;
  right: 0 !important;
  text-align: left !important;
  top: 18px !important;
  white-space: nowrap !important;
  -moz-border-radius: 4px 0 4px 4px !important; /* LTR */
  -webkit-border-bottom-left-radius: 4px !important;
  -webkit-border-bottom-right-radius: 4px !important;
  -webkit-border-top-right-radius: 0 !important; /* LTR */
  -webkit-border-top-left-radius: 4px !important; /* LTR */
  border-radius: 4px 0 4px 4px !important; /* LTR */
}
.contextual-links-region:hover a.contextual-links-trigger,
div.contextual-links-active a.contextual-links-trigger,
div.contextual-links-active ul.contextual-links {
  display: block !important;
}
ul.contextual-links li {
  display: list-item !important;
  line-height: 100% !important;
  list-style: none !important;
  list-style-image: none !important;
  margin: 0 !important;
  padding: 0 !important;
  &:before, &:after {
    display: none;
  }
}
div.contextual-links-wrapper a {
  text-decoration: none !important;
}
ul.contextual-links li a {
  color: #333 !important;
  display: block !important;
  margin: 0.25em 0 !important;
  padding: 0.25em 1em 0.25em 0.5em !important;
}
ul.contextual-links li a:hover {
  background-color: #bfdcee !important;
}
